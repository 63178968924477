<template>
  <cw-page
    icon="check"
    class="cw-business-loan-signing-complete"
  >
    <template #hero-title>
      <translate>
        The loan application has been signed
      </translate>
    </template>
    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p>
          <translate>
            Your company’s signature clause is complete for the loan application.
          </translate>
        </p>
        <p>
          <translate>
            We have received the required signatures and granted a loan to your company. The loan
            amount shall be transferred immediately to the account specified in the application.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CwBusinessLoanSigningComplete',

  created() {
    this.setPurchase(false);
  },

  methods: {
    ...mapActions({
      setPurchase: 'application/setPurchase',
    }),
  },
};
</script>
